import {createSlice} from "@reduxjs/toolkit";
import {DFS_CONSTANTS} from "../../../utils/constants";

const uiSlice = createSlice({
    name: "ui",
    initialState: {
        theme: localStorage.getItem(DFS_CONSTANTS.THEME_VARIANT) || "dark",
        toggleDrawer: false,
        selectedCategory: ""
    },
    reducers: {
        toggleDrawer: (state, action) => {
            state.toggleDrawer = action.payload
        },
        toggleTheme: (state) => {
            state.theme = state.theme === 'light' ? 'dark' : 'light';
            localStorage.setItem(DFS_CONSTANTS.THEME_VARIANT, state.theme);
        },
        selectCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        resetCategory: (state) => {
            state.selectedCategory = "";
        }
    }
});

const {reducer, actions} = uiSlice;
export const selectUI = state => state.ui;
export const UI_ACTION_CREATORS = {
    toggleDrawer: actions.toggleDrawer,
    toggleTheme: actions.toggleTheme,
    selectCategory: actions.selectCategory,
    resetCategory: actions.resetCategory,
};
export default reducer;
