import {createSlice} from "@reduxjs/toolkit";

const marketingSlice = createSlice({
    name: "marketing",
    initialState: {
        step: 0,
        answers: []
    },
    reducers: {
        next: (state) => {
            state.step = state.step + 1;
        },
        previous: (state) => {
            if (state.step > 0) {
                state.step = state.step - 1;
            }
        },
        goTo: (state, action) => {
            if (action.payload >= 0) {
                state.step = action.payload;
            }
        }
    }
});

const {reducer, actions} = marketingSlice;
export const selectUI = state => state.marketing;
export const MARKETING_ACTION_CREATORS = {next: actions.next, previous: actions.previous, goTo: actions.goTo};
export default reducer;