import './App.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUI} from "./redux/features/ui/ui-slice";
import {THEMES} from "./utils/themes";
import {Route, Routes, useLocation} from "react-router";
import {lazy, Suspense, useEffect} from "react";
import {AnimatePresence} from "framer-motion";
import Splash from "./components/shared/splash";

const HomePage = lazy(() => import("./pages/home-page"));
const AboutPage = lazy(() => import("./pages/about-page"));
const ContactPage = lazy(() => import("./pages/contact-page"));
const NotFoundPage = lazy(() => import("./pages/not-found-page"));
const ServicesPage = lazy(() => import("./pages/services-page"));
const PortfolioPage = lazy(() => import("./pages/portfolio-page"));
const PackagesPage = lazy(() => import("./pages/packages-page"));
const LogoBriefPage = lazy(() => import("./pages/logo-brief-page"));
const MarketingBriefPage = lazy(() => import("./pages/marketing-brief-page"));

function App() {

    const {theme} = useSelector(selectUI);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }, [location.pathname]);

    return (
        <ThemeProvider theme={theme === 'dark' ? THEMES.darkTheme : THEMES.lightTheme}>
            <CssBaseline/>
            <AnimatePresence initial={true} mode='sync'>
                <Routes location={location}>
                    <Route path="/" element={<Suspense fallback={<Splash/>}> <HomePage/></Suspense>}/>
                    <Route path="/us" element={<Suspense fallback={<Splash/>}> <AboutPage/></Suspense>}/>
                    <Route path="/contact" element={<Suspense fallback={<Splash/>}> <ContactPage/></Suspense>}/>
                    <Route path="/packages" element={<Suspense fallback={<Splash/>}> <PackagesPage/></Suspense>}/>
                    <Route path="/services" element={<Suspense fallback={<Splash/>}> <ServicesPage/></Suspense>}/>
                    <Route path="/portfolio" element={<Suspense fallback={<Splash/>}> <PortfolioPage/></Suspense>}/>
                    <Route path="/logo-brief" element={<Suspense fallback={<Splash/>}> <LogoBriefPage/></Suspense>}/>
                    <Route path="/marketing-brief"
                           element={<Suspense fallback={<Splash/>}> <MarketingBriefPage/></Suspense>}/>
                    <Route path="*" element={<Suspense fallback={<Splash/>}> <NotFoundPage/></Suspense>}/>
                </Routes>
            </AnimatePresence>
        </ThemeProvider>
    );
}

export default App;
