import {createSlice} from "@reduxjs/toolkit";

const logoSlice = createSlice({
    name: "logo",
    initialState: {
        step: 0,
        logoBrief: {
            name: ""
        }
    },
    reducers: {
        next: (state, action) => {

        },
        previous: (state, action) => {
        },
        goTo: (state, action) => {
        }
    }
});

const {reducer, actions} = logoSlice;
export const selectUI = state => state.logo;
export const LOGO_ACTION_CREATORS = {next: actions.next, previous: actions.previous, goTo: actions.goTo};
export default reducer;
