import {configureStore} from "@reduxjs/toolkit";
import uiReducer from "./../features/ui/ui-slice";
import logoReducer from "./../features/logo/logo-slice";
import marketingReducer from "./../features/marketing/marketing-slice";

const store = configureStore({
    reducer: {
        ui: uiReducer,
        logo: logoReducer,
        marketing: marketingReducer
    },
    devTools: true
});

export default store;
