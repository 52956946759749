import {Box, CardMedia, CircularProgress, Container, Stack, Typography} from "@mui/material";
import logo from "../../assets/images/logo/logo.png";
import whiteLogo from "../../assets/images/logo/defuturelogowhite.png";
import {useSelector} from "react-redux";
import {selectUI} from "../../redux/features/ui/ui-slice";
import {AnimatePresence, motion} from "framer-motion";

const Splash = () => {
    const {theme} = useSelector(selectUI);
    return (
        <Box sx={{maxHeight: "100vh", height: "100vh", display: "flex", alignItems: "center"}}>
            <Container>
                <Stack spacing={3}>
                    <Box>
                        <Typography
                            variant="h4"
                            align="center"
                            sx={{
                                textTransform: "uppercase",
                                color: "text.primary",
                                fontWeight: 700,
                                letterSpacing: 1.4,
                                mb: 3
                            }}>
                            De-Future Solution
                        </Typography>

                        <Typography
                            variant="body1"
                            align="center"
                            sx={{
                                color: "text.secondary",
                                fontWeight: 600
                            }}>
                            Join the Conversation
                        </Typography>
                    </Box>
                    <Box>
                        <Stack justifyContent="center" direction="row" spacing={3}>
                            <AnimatePresence>
                                {theme === "light" && (
                                    <Stack
                                        whileInView={{opacity: 1}}
                                        initial={{opacity: 0}}
                                        exit={{opacity: 0}}
                                        transition={{
                                            duration: 0.3,
                                        }}
                                        component={motion.div}
                                        direction="row">
                                        <CardMedia
                                            component="img"
                                            src={logo}
                                            sx={{width: 200, height: 200, objectFit: "contain", maxWidth: "100%"}}
                                        />
                                    </Stack>
                                )}
                            </AnimatePresence>
                            <AnimatePresence>
                                {theme === "dark" && (
                                    <Stack
                                        whileInView={{opacity: 1}}
                                        initial={{opacity: 0}}
                                        exit={{opacity: 0}}
                                        transition={{
                                            duration: 0.3,
                                        }}
                                        component={motion.div}
                                        direction="row">
                                        <CardMedia
                                            component="img"
                                            src={whiteLogo}
                                            sx={{width: 200, height: 200, objectFit: "contain", maxWidth: "100%"}}
                                        />
                                    </Stack>
                                )}
                            </AnimatePresence>
                        </Stack>
                    </Box>
                    <Box>
                        <Stack justifyContent="center" direction="row" spacing={3}>
                            <CircularProgress
                                variant="indeterminate"
                                color={theme === "dark" ? "secondary" : "primary"}
                                size={50}
                            />
                        </Stack>
                    </Box>
                    <Box>
                        <Typography
                            variant="h6"
                            align="center"
                            sx={{
                                textTransform: "none",
                                color: "text.secondary",
                                letterSpacing: 1.4,
                                fontWeight: 700
                            }}>
                            Loading. Please wait...
                        </Typography>
                    </Box>
                </Stack>
            </Container>
        </Box>
    )
}

export default Splash;
