import {createTheme} from "@mui/material";

const lightTheme = createTheme({
    typography: {
        fontFamily: "Lexend Deca,GoogleSans, Urbanist, EuclidCircularB, SatrevaNova"
    },
    palette: {
        primary: {
            main: "#112d56"
        },
        secondary: {
            main: "#ffe601"
        },
        background: {
            default: "#f2f4f5",
            paper: "#ffffff",
            yellow: "rgba(255,230,1,0.6)",
            glass: "rgba(17,45,86,0.45)",
        },
        text: {
            primary: "#191919",
            secondary: "#585b63"
        },
        light: {
            primary: "rgba(17,45,86,0.05)",
            secondary: "rgba(255,230,1,0.3)",
            background: "rgba(211,211,211,0.05)",
            white: "rgba(255, 255, 255, 0.05)"
        },
        mode: "light"
    },
    shape: {
        borderRadius: 32
    }
});

const darkTheme = createTheme({
    typography: {
        fontFamily: "Lexend Deca, GoogleSans, Urbanist, EuclidCircularB, SatrevaNova"
    },
    palette: {
        primary: {
            main: "#112d56"
        },
        secondary: {
            main: "#ffe601"
        },
        background: {
            default: "#202124",
            paper: "#303136",
            yellow: "rgba(255,230,1,0.6)",
            glass: "rgba(17,45,86,0.25)",
        },
        text: {
            primary: "#f8f9fa",
            secondary: "#d2d3d7"
        },
        light: {
            primary: "rgba(17,45,86,0.5)",
            secondary: "rgba(255,230,1,0.05)",
            background: "rgba(211,211,211,0.05)",
            white: "rgba(255, 255, 255, 0.05)"
        },
        mode: "dark"
    },
    shape: {
        borderRadius: 32
    }
});


export const THEMES = {lightTheme, darkTheme};
